import '../styles/base.css';
import '../styles/globals.scss';
import * as gtag from 'utils/gtag';
import { AlertProvider } from 'components/layout/AlertProvider';
import { AppProps } from 'next/app';
import { IronSessionData } from 'iron-session';
import { SessionProvider } from 'components/layout/SessionProvider';
import { useRouter } from 'next/router';
import Head from 'next/head';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import Script from 'next/script';
import type { NextPage } from 'next';
interface MyAppProps extends AppProps {
  session: IronSessionData;
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = MyAppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp(props: AppPropsWithLayout) {
  const { Component, pageProps, session } = props;
  const getLayout = Component.getLayout ?? (page => page);
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <SessionProvider session={session}>
        <AlertProvider id="global-alerts">
          {getLayout(<Component {...pageProps} />)}
        </AlertProvider>
      </SessionProvider>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-PHTGPECJ0N"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-PHTGPECJ0N', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
    </>
  );
}
